<template>
  <notifications
    animation-name="v-fade-right"
    position="right"
    classes="notifications"
    width="350"
    ignoreDuplicates
  >
    <template slot="body" slot-scope="props">
      <div
        :class="
          props.item.type
            ? 'custom-template custom-template-' + props.item.type
            : 'custom-template'
        "
      >
        <v-icon
          size="30"
          :class="
            props.item.type
              ? 'custom-template-icon custom-template-icon-' + props.item.type
              : 'custom-template-icon'
          "
          >{{
            props.item.type ? iconsMap[props.item.type] : iconsMap['info']
          }}</v-icon
        >
        <div class="custom-template-content">
          <div class="custom-template-title">
            {{ props.item.title }}
          </div>
          <div class="custom-template-text" v-html="props.item.text"></div>
        </div>
        <v-icon class="custom-template-close" @click="props.close"
          >close</v-icon
        >
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'Notification',
  data: function() {
    return {
      iconsMap: {
        info: 'mdi-information-variant',
        success: 'mdi-checkbox-marked-circle-outline',
        warn: 'mdi-alert-outline',
        error: 'mdi-alert-octagon-outline',
      },
    };
  },
  computed: {
    layout: function() {
      return this.$route.meta.layout || 'main-layout';
    },
  },
  methods: {
    getIconClasses: function() {},
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.custom-template {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  text-align: left;
  font-size: 14px;
  margin-bottom: 5px;
  padding-left: 5px;
  opacity: 0.95;

  border-style: solid none solid solid;
  border-width: 1px 0 1px 5px;
  background: var(--v-accent-lighten4);
  border-color: var(--v-accent-darken1);

  &-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
  }

  &-text {
    overflow-wrap: break-word;
    width: 245px;
    max-height: 75px;
  }

  &-icon {
    color: var(--v-accent-darken1) !important;
    padding: 0 5px;

    &-success {
      color: var(--v-success-darken1) !important;
    }

    &-warn {
      color: var(--v-warning-base) !important;
    }

    &-error {
      color: var(--v-error-base) !important;
    }
  }

  &-success {
    background: var(--v-success-lighten5);
    border-color: var(--v-success-base);
  }

  &-warn {
    background: var(--v-warning-lighten5);
    border-color: var(--v-warning-base);
  }

  &-error {
    background: var(--v-error-lighten4);
    border-color: var(--v-error-base);
  }

  &-close {
    flex: 0 1 auto;
    opacity: 0.2;
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      opacity: 0.8;
    }
  }

  &-content {
    padding: 10px;
    flex: 1 0 auto;
  }
}

.v-fade-right-enter-active,
.v-fade-right-leave-active,
.v-fade-right-move {
  transition: all 0.5s;
}

.v-fade-right-enter,
.v-fade-right-leave-to {
  opacity: 0;
  transform: translateX(350px) scale(0.2);
}
</style>
