import API from './API.service';

export const catalogService = {
  getAll,
};

function getAll() {
  let endpoint = `/parameter`;
  return API.get(endpoint);
}
