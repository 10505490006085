<template>
  <div class="container pb-12">
    <v-divider class="my-5 panels divider"></v-divider>
    <v-container fluid class="px-5">
      <v-row>
        <v-text-field
          v-model="nielsenDate"
          label="Nielsen GTC Date"
          outlined
          dense
          append-icon="mdi-calendar"
          class="center-text"
          readonly
        ></v-text-field>
      </v-row>
      <v-row>
        <hierarchy-filters
          :dataSource="dataSource"
          :projectConfig="currentConfig"
          @update:filters="updateFiltersFromSelection"
          :key="renderKey"
        ></hierarchy-filters>
      </v-row>
    </v-container>
    <v-footer color="white" padless>
      <v-row justify="center" no-gutters class="mt-4 pb-12 pl-6 pr-6">
        <v-col cols="12" class="ma-2">
          <v-btn color="primary" :disabled="!applyEnabled" @click="apply" block>
            Apply
          </v-btn>
        </v-col>
        <v-col class="ma-2">
          <v-btn color="primary" outlined @click="cancel" block>
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
<script>
import {
  computed,
  ref,
  // watch
} from '@vue/composition-api';
// import useDataSource from '@/composables/data-sources';
import HierarchyFilters from '@/components/custom/HierarchyFilters';
import { catalog, project } from '@/store-mapping';
// import store from '@/store';
import _ from 'lodash';

export default {
  name: 'ProjectConfiguration',
  components: {
    HierarchyFilters,
  },
  setup(props, ctx) {
    const { getAll, state: catalogState } = catalog;
    const { state: projectState } = project;

    const dataSource = computed(() => catalogState.parameters);

    const nielsenDate = computed(() => {
      if (!dataSource.value.dump_date) return '';
      var pattern = /(\d{4})(\d{2})(\d{2})/;
      return dataSource.value.dump_date.replace(pattern, '$3-$2-$1');
    });

    const loadingCatalogs = computed(() => catalogState.loading);
    getAll();

    const panels = ref([0]); // first accordion open

    const renderKey = ref(0);

    const currentConfig = computed(() => {
      const {
        dump_date,
        category,
        segment,
        brand,
        selected_countries,
      } = projectState;

      const countries = selected_countries.map(sc => sc.country);
      return { dump_date, category, segment, brand, countries };
    });

    const filters = ref({
      regions: [],
      countries: [],
      category: [],
      segment: [],
      brand: [],
    });

    const forceRender = () => {
      renderKey.value += 1;
    };

    const loadCurrentConfiguration = () => {
      var clone = _.cloneDeep(currentConfig.value);
      // add my-component component in DOM
      // this.renderComponent = true;
      // dump_date.value = clone.dump_date;

      filters.value = {
        category: clone.category,
        segment: clone.segment,
        brand: clone.brand,
        countries: close.countries,
      };

      // if (isDataSourceSelected.value) {
      // catalog.getAll(project.value);
      // } else {
      //   filters.value = ctx.options.data().filters;
      // }
      forceRender();
    };

    // watch(
    //   currentConfig,
    //   () => {
    //     loadCurrentConfiguration();
    //   },
    //   { deep: true }
    // );

    loadCurrentConfiguration();

    // const isDirty = computed(() => {
    //   return !_.isEqual(currentConfig.value, config.value);
    // });

    const applyEnabled = computed(
      () =>
        filters.value.category != null &&
        filters.value.segment != null &&
        filters.value.brand != null &&
        filters.value.countries != null &&
        filters.value.countries.length > 0
    );

    const updateFiltersFromSelection = selectedFilters => {
      filters.value = selectedFilters;
    };

    const apply = () => {
      ctx.emit('apply', {
        dump_date: dataSource.value.dump_date,
        ...filters.value,
      });
    };

    const cancel = () => {
      // loadCurrentConfiguration();
      ctx.emit('cancel');
    };

    return {
      loadingCatalogs,
      panels,
      dataSource,
      nielsenDate,
      currentConfig,
      renderKey,
      // // config,
      // project,
      filters,
      // // catalogs,
      // isDirty,
      applyEnabled,
      // applyingConfiguration,
      updateFiltersFromSelection,
      apply,
      cancel,
      // //Data sources
      // loadingDS,
      // loadingCatalogsDS,
      // isDataSourceSelected,
    };
  },
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.container {
  width: 100%;
}

.panels > .v-expansion-panel {
  border-bottom: 1px solid var(--v-primary-base);
}

.panels > .v-expansion-panel:first-child {
  border-top: 1px solid var(--v-primary-base);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.panels > .v-expansion-panel:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.center-text {
  text-align: center;
  text-align-last: center;
}

.divider {
  border-color: var(--v-primary-base) !important;
}
</style>
