<template>
  <main-layout withRightMenu>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card min-height="82vh">
        <v-container fluid>
          <v-row justify="space-between" class="mt-5" no-gutters>
            <v-col cols="4" class="ml-5">
              <v-text-field
                v-model="project.name"
                label="Project Name"
                :rules="projectNameRules"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="pink"
                @click.stop="showRightMenu(true)"
                class="mr-3"
                :disabled="rightMenu.show"
                text
              >
                <v-icon left>edit</v-icon>
                Edit configuration
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters class="ml-2">
            <v-col cols="6">
              <v-container fluid>
                <v-row>
                  <v-col cols="10">
                    <v-select
                      v-model="project.test_country"
                      :items="testCountryList"
                      item-value="key"
                      item-text="value"
                      label="Test Country"
                      hide-details
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model.number="project.plan_marketing_spend"
                      label="Marketing Spend"
                      :rules="requiredNumberRules"
                      class="text-right"
                      style="width:180px"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model.number="project.plan_distribution"
                      label="Distribution (end Year ACV)"
                      :rules="requiredNumberRules"
                      class="text-right"
                      suffix="%"
                      style="width:180px"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col>
              <v-container>
                <v-row>
                  <v-col cols="6" class="ml-5">
                    <v-text-field
                      v-model.number="project.bases_concept_id"
                      label="Bases Concept ID"
                      :rules="requiredNumberRules"
                      class="text-right"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <h1 class="display-1 ml-5">Bases</h1>
              <v-divider class="my-3 panels divider"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <span class="text-h5 black--text pl-4 pt-4 d-inline-block"
                >Reseach Inputs</span
              >
              <v-container class="ml-5">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.number="project.trial_tried_product"
                      label="% HH Tried Product (YR1)"
                      :rules="requiredNumberRules"
                      class="text-right"
                      suffix="%"
                      style="width:180px"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model.number="project.repeat_at_least_once"
                      label="% HH Repeat purchases at least once"
                      :rules="requiredNumberRules"
                      class="text-right"
                      suffix="%"
                      style="width:180px"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-text-field
                      v-model.number="project.trial_packs_purchased"
                      label="# Package purchased at trial"
                      :rules="requiredNumberRules"
                      class="text-right"
                      style="width:180px"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col class="ml-5">
                    <v-text-field
                      v-model.number="project.repeat_purchases_per_hh"
                      label="# Repeat purchases per HH"
                      :rules="requiredNumberRules"
                      class="text-right"
                      style="width:180px"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.number="project.cps"
                      label="CPS"
                      :rules="requiredNumberRules"
                      class="text-right"
                      style="width:180px"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model.number="project.repeat_pack_buy_each"
                      label="# Packs buy each repeat purchase"
                      :rules="requiredNumberRules"
                      class="text-right"
                      style="width:180px"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="6">
              <span class="text-h5 black--text pl-4 pt-4 d-inline-block"
                >Cannibalisation</span
              >
              <v-container class="ml-5" fluid>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.number="project.sova"
                      label="SOVA"
                      :rules="requiredNumberRules"
                      class="text-right"
                      style="width:180px"
                      suffix="%"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model.number="project.fga_volume"
                      label="FGA Volume"
                      :rules="requiredNumberRules"
                      class="text-right"
                      suffix="%"
                      style="width:180px"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model.number="project.bases_volume_forecast"
                      label="Bases Volume Forecast"
                      :rules="requiredNumberRules"
                      class="text-right"
                      style="width:180px"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model.number="project.fga_value"
                      label="FGA Value"
                      :rules="requiredNumberRules"
                      class="text-right"
                      suffix="%"
                      style="width:180px"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>

          <v-row justify="center" class="mx-5 my-0"> </v-row>
          <v-row justify="center" class="ma-2">
            <v-col cols="12">
              <npd-sku-details
                v-model="project.sku_rows"
                :key="skuDetailsKey"
              />
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-5 mt-10">
            <v-card>
              <v-container fluid>
                <v-row>
                  <v-col cols="6">
                    <h3 class="font-weight-regular pl-5">Forecast</h3>
                    <v-divider class="my-3 panels divider"></v-divider>
                  </v-col>
                  <v-col offset="3" cols="3">
                    <v-select
                      v-model.number="project.forecast_basis"
                      :items="forecastBasisList"
                      item-value="key"
                      item-text="value"
                      label="Forecast Basis"
                      hide-details
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <forecast-table
                    v-model="forecastData"
                    :loading="loadingForecast"
                    @update="forecastTableUpdate"
                  />
                </v-row>
                <v-row class="mt-3 mr-3 mb-1">
                  <v-col>
                    <v-row justify="end">
                      <v-btn
                        color="green darken-1"
                        class="ml-3"
                        outlined
                        :loading="exportingToExcel"
                        :disabled="!isValidProject2Export"
                        @click="exportToExcel(forecastPerCountry)"
                        ><v-icon left>mdi-file-excel</v-icon>Export to
                        Excel</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-row>
          <v-row justify="space-between" class="ml-3">
            <v-col>
              <!-- <v-checkbox
                v-model="project.published"
                label="Share project"
                class="mt-1 ml-5"
              ></v-checkbox> -->
            </v-col>
            <v-col cols="6">
              <v-row justify="end" class="mr-4">
                <v-btn
                  color="red darken-3"
                  class="mr-3  button"
                  outlined
                  hidden
                  :disabled="!allowToDelete"
                  @click="confirmDelete = true"
                  ><v-icon left>mdi-trash-can-outline</v-icon>Delete</v-btn
                >
                <v-btn
                  class="mr-3"
                  color="blue darken-3 button"
                  outlined
                  :disabled="!allowToNew"
                  @click="createNew"
                  ><v-icon left>mdi-shape-rectangle-plus</v-icon> New</v-btn
                >
                <!-- <v-btn
                  color="success button"
                  :loading="storing"
                  :disabled="!allowToSave"
                  @click="store"
                  ><v-icon left>mdi-content-save-outline</v-icon>Save</v-btn -->
                <v-btn color="success button" :loading="storing" @click="store"
                  ><v-icon left>mdi-content-save-outline</v-icon>Save</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
    <template v-slot:rightmenu>
      <project-configuration
        v-if="rightMenu.show"
        @apply="applySettings"
        @cancel="showRightMenu(false)"
      />
    </template>

    <confirmation-message
      key="pendingChanges"
      :show="pendingChangesDialog"
      icon="warning"
      title="Pending changes!"
      :message="
        `Are you sure you want to discard all pending changes for project '${this.project.name}'?`
      "
      button1-text="Discard"
      @button1="dialogContinue"
      button2-text="Cancel"
      @button2="pendingChangesDialog = false"
    />

    <confirmation-message
      key="confirmationDelete"
      :show="confirmDelete"
      icon="warning"
      title="Delete permanently"
      :message="
        `Are you sure you want to delete the project '${project.name}'?.\n This action cannot not be undone.`
      "
      button1-text="Cancel"
      @button1="confirmDelete = false"
      button2-text="Delete"
      button2-color="error darken-1"
      button2-type="solid"
      @button2="deleteCurrentProject"
    />
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </main-layout>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
// import NpdSkuDetails from '@/components/forecast/partials/NPDSkuDetails';
import ProjectConfiguration from '@/components/forecast/partials/ProjectConfiguration';
// import ConfigurationSummary from '@/components/common/ConfigurationSummary';
import NpdSkuDetails from '@/components/forecast/partials/SkuTable';
import ForecastTable from '@/components/forecast/partials/ForecastTable';
import ConfirmationMessage from '@/components/common/ConfirmationMessage';
import { viewTypeList } from '@/global/groundForecast';
import { mutationTypes } from '@/store';
import _ from 'lodash';

const forecast_params = [
  'dump_date',
  'category',
  'Segmend',
  'brand',
  'test_country',
  'sova',
  'bases_volume_forecast',
  'forecast_basis',
  'selected_countries',
  'sku_rows',
];

export default {
  name: 'Project',
  components: {
    ProjectConfiguration,
    // ConfigurationSummary,
    ConfirmationMessage,
    NpdSkuDetails,
    ForecastTable,
  },
  data: function() {
    return {
      title: 'List of Projecs',
      pendingChangesDialog: false,
      valid: true,
      unwatchRef: [],
      tab: 'table',
      dialogContinue: () => {},
      confirmDelete: false,
      config: null,
      testCountryList: [], //maybe change for a computed
      //Renders, this allow to force the render of the object
      skuDetailsKey: 0,
      forecastData: {
        forecast_data: [],
        test_country: {
          country: null,
          skus: [],
        },
      },
    };
  },
  created: function() {
    this.setRightMenuSettings({
      title: 'Configuration',
      width: 350,
    });

    this.showRightMenu(true);

    // this.loadCurrentProject(false);

    if (!_.isEmpty(this.paramProjectId)) {
      if (this.dirty) {
        this.showPendingChangesDialog(() => {
          this.loadOrCreateProject();
        });
      } else {
        this.loadOrCreateProject();
      }
    }
  },
  watch: {
    forecast(newValue) {
      const flatData = newValue.forecast_data.flatMap(i =>
        i.skus.map(x => {
          return { ...x, country: i.country };
        })
      );

      this.forecastData = {
        forecast_data: flatData,
        test_country: this.forecast.test_country,
      };
    },
  },
  computed: {
    forecastPerCountry() {
      const groupBy = (xs, key) => {
        return xs.reduce(function(pv, cv) {
          (pv[cv[key]] = pv[cv[key]] || []).push(cv);
          delete cv[key];
          return pv;
        }, {});
      };

      // this.forecastData.forecast.reduce((prev, curr)=> { forecast_data} )

      const grouped = groupBy(this.forecastData.forecast_data, 'country');

      return {
        forecastData: Object.keys(grouped).map(k => ({
          country: k,
          skus: grouped[k],
        })),
        test_country: this.forecastData.test_country,
      };

      // return groupBy(this.forecastData.forecast, 'country');

      // return this.forecastData.forecast.map(fd => {
      //   const { country, ...skus } = fd;
      //   return { country, skus };
      // });
    },
    paramProjectId() {
      if (this.$route.params && this.$route.params.projectId)
        return this.$route.params.projectId;
      return null;
    },
    allowToSave() {
      return (
        // !_.isEmpty(this.configuration) &&
        this.valid && this.dirty && !this.loadingForecast
      );
    },
    allowToDelete() {
      return (
        this.project != null && this.project.id != null && !this.loadingForecast
      );
    },
    allowToNew() {
      return !this.loadingForecast;
    },
    exportEnabled() {
      return true;
    },
    getViewTypeList() {
      return viewTypeList;
    },
    forecastBasisList() {
      return ['Category', 'Segment'];
    },
    ...mapState({
      rightMenu: state => state.app.rightMenu,
    }),
    ...mapState('project', {
      project: state => state.project,
      // configuration: state => state.project.config.preferences,
      forecast: state => state.forecast,
      loading: state => state.loading,
      loadingForecast: state => state.loadingForecast,
      exportingToExcel: state => state.exportingToExcel,
      storing: state => state.storing,
      dirty: state => state.dirty,
    }),
    isValidConfiguration() {
      return (
        !_.isEmpty(this.project.dump_date) &&
        !_.isEmpty(this.project.category) &&
        !_.isEmpty(this.project.segment) &&
        !_.isEmpty(this.project.brand) &&
        this.project.selected_countries &&
        this.project.selected_countries.length > 0
      );
    },
    isValidForecastParameters() {
      return (
        !_.isEmpty(this.project.test_country) &&
        _.isNumber(this.project.sova) &&
        _.isNumber(this.project.bases_volume_forecast) &&
        !_.isEmpty(this.project.forecast_basis) &&
        this.project.bases_volume_forecast > 0 &&
        this.project.sku_rows &&
        this.project.sku_rows.length > 0
      );
    },
    isValidProject2Export() {
      return (
        this.isValidConfiguration &&
        this.isValidForecastParameters &&
        // _.isNumber(this.project.bases_concept_id) &&
        _.isNumber(this.project.plan_marketing_spend) &&
        _.isNumber(this.project.plan_distribution) &&
        _.isNumber(this.project.cps) &&
        _.isNumber(this.project.trial_tried_product) &&
        _.isNumber(this.project.trial_packs_purchased) &&
        _.isNumber(this.project.repeat_at_least_once) &&
        _.isNumber(this.project.repeat_purchases_per_hh) &&
        _.isNumber(this.project.repeat_pack_buy_each) &&
        _.isNumber(this.project.fga_volume) &&
        _.isNumber(this.project.fga_value)
      );
    },
  },
  mounted() {
    this.pendingChangesDialog = false;
  },
  methods: {
    forceRender() {
      this.skuDetailsKey += 1;
    },
    forecastTableUpdate(item) {
      const sku = this.project.selected_countries.find(
        sc => sc.country == item.country
      );
      sku.price_override = item.price_override;
      sku.nr_override = item.nr_override;
      if (this.isValidForecastParameters) this.calculateForecast();
    },
    loadCurrentProject(track = true) {
      track;
      // this.stopTracking();
      // this.projectForm = this.project;
      // if (track) console.log('track', track);
      this.startTracking();
    },
    loadOrCreateProject() {
      if (this.paramProjectId === 'new') {
        this.new();
      } else {
        this.loadExistingProject(this.$route.params.projectId);
      }
    },
    async loadExistingProject(projectId) {
      this.showRightMenu(false);

      this.stopTracking();
      await this.loadProject(projectId);
      this.startTracking();

      if (this.isValidForecastParameters) this.calculateForecast();
      this.forceRender();
    },
    new() {
      this.stopTracking();
      this.createNewProject().then(() => {
        this.$refs.form.resetValidation();
        this.startTracking();
        this.forceRender();
        this.showRightMenu(true);
      });
    },
    async deleteCurrentProject() {
      await this.deleteProject(this.project.project_id);
      this.confirmDelete = false;
      this.$router.push('/');
    },

    showPendingChangesDialog(continueCallBack) {
      this.$nextTick(() => {
        this.pendingChangesDialog = true;
      });
      this.dialogContinue = () => {
        this.pendingChangesDialog = false;
        continueCallBack();
      };
    },
    startTracking() {
      forecast_params.forEach(p => {
        const w = this.$watch(
          `project.${p}`,
          _.debounce(newValue => this.projectTracker(p, newValue), 150)
        );
        this.unwatchRef.push(w);
      });
    },
    stopTracking() {
      if (this.unwatchRef.length > 0) this.unwatchRef.forEach(f => f());
    },
    projectTracker() {
      if (this.isValidForecastParameters) this.calculateForecast();
    },
    exportToCSV() {
      this.exportForecastToCSV();
    },
    store() {
      if (this.$refs.form && this.$refs.form.validate()) {
        this.stopTracking();
        this.storeProject()
          .then(data => {
            this.$notify({
              title: this.title,
              text: `Project ${data.name} saved!`,
              type: 'success',
            });
          })
          .catch(() => {
            this.$notify({
              title: this.title,
              text: `It was not possible to save the project!`,
              type: 'error',
            });
          })
          .finally(() => this.startTracking());
      }
    },
    createNew() {
      // const goToWizard = () => this.$router.push('/forecast');
      this.new();

      // if (this.dirty) {
      //   this.showPendingChangesDialog(goToWizard);
      // } else {
      //   goToWizard();
      // }
      // goToWizard;
    },
    applySettings: function(config) {
      const { dump_date, category, segment, brand, countries } = config;

      this.project.dump_date = dump_date;
      this.project.category = category;
      this.project.segment = segment;
      this.project.brand = brand;
      this.project.selected_countries = [];
      countries.forEach(c => {
        this.project.selected_countries.push({
          country: c,
          price_override: 0.0,
          nr_percentage: 0.5,
          nr_override: 0.0,
        });
      });

      this.project.test_country = null;
      this.testCountryList = countries;
      this.config = config;
      this.showRightMenu(false);
    },
    ...mapMutations('project', {
      setProject: mutationTypes.project.SET_PROJECT,
      setForecastChanged: mutationTypes.project.FORECAST_CHANGED,
    }),
    ...mapActions('app', ['showRightMenu', 'setRightMenuSettings']),
    ...mapActions('project', [
      'storeProject',
      'loadProject',
      'createNewProject',
      'deleteProject',
      'applyConfiguration',
      'loadForecast',
      'exportToExcel',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 100px;
}
.viewType {
  width: 148px;
}
.v-text-field__slot {
  input {
    text-align: right;
  }
}

.text-center {
  text-align: center;
  text-align-last: center;
}
.text-right {
  text-align: right;
  text-align-last: right;
}
.divider {
  border-color: var(--v-primary-base) !important;
}
</style>
