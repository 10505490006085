/**
 * Python style zip arrays.
 * @param  {...Array} arrays Arrays to be zipped together
 */
const zip = (...arrays) => {
  const length = Math.min(...arrays.map(arr => arr.length));
  return Array.from({ length }, (value, index) =>
    arrays.map(array => array[index])
  );
};

/**
 * Parse key-values to a query string, outputs arrays as repeated
 * keys e.g. { sort: ['a', 'b'] } => "sort=a&sort=b"
 * @param {Any} params
 */
function parseQueryParams(params) {
  const keys = Object.keys(params);
  let query = '';

  keys.forEach(key => {
    if (params[key] !== null) {
      const isParamTypeObject = typeof params[key] === 'object';
      const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

      if (!isParamTypeObject) {
        query += `${key}=${params[key]}&`;
      }

      if (isParamTypeObject && isParamTypeArray) {
        params[key].forEach(element => {
          query += `${key}=${element}&`;
        });
      }
    }
  });

  return query ? query.slice(0, -1) : query;
}

/**
 * Convert vuetify data table options to axios pagination params.
 * @param {*} paginationOption
 */
export function paginateQueryParams(paginationOption) {
  const page = paginationOption.page - 1; // server is zero-indexed; UI table not
  const size =
    paginationOption.itemsPerPage > 0 ? paginationOption.itemsPerPage : null;
  const sort = zip(paginationOption.sortBy, paginationOption.sortDesc).map(
    ([key, desc]) => `${key},${desc ? 'desc' : 'asc'}`
  );

  return {
    params: { page, size, sort },
    paramsSerializer: params => parseQueryParams(params),
  };
}
