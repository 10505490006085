<template>
  <v-data-table
    :headers="headers"
    :items="forecast"
    :loading="loading"
    disable-pagination
    hide-default-footer
    fixed-header
    sort-by="calories"
    group-by="country"
    class="elevation-1"
    style="width: 100%;"
  >
    <template v-slot:item.units_forecast="{ item }">
      {{ item.units_forecast | numberFormat }}
    </template>
    <template v-slot:item.value_forecast="{ item }">
      {{ item.value_forecast | currency }}
    </template>

    <!-- EDIT -->
    <template v-slot:item.price_override="{ item }">
      <span v-if="disabled">{{ item.price_override | percentage(1) }}</span>
      <v-edit-dialog
        v-if="!disabled"
        large
        persistent
        @save="saveEdit('price_override', item, edit_table_value)"
        @open="edit_table_value = item.price_override * 100"
      >
        <span class="editable-field">{{
          item.price_override | percentage(1)
        }}</span>
        <template v-slot:input>
          <v-text-field
            v-model="edit_table_value"
            label="Price Override"
            :rules="requiredNumberRules"
            class="text-right mt-4 text-h6 text-right"
            suffix="%"
            style="width:180px"
            outlined
            dense
            autofocus
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>

    <template v-slot:item.adjusted_value_forecast="{ item }">
      {{ item.adjusted_value_forecast | currency }}
    </template>
    <template v-slot:item.nr_percentage="{ item }">
      {{ item.nr_percentage | percentage(1) }}
    </template>
    <template v-slot:item.nr_forecast="{ item }">
      {{ item.nr_forecast | currency }}
    </template>
    <template v-slot:item.nr_override="{ item }">
      <span v-if="disabled">{{ item.nr_override | percentage(1) }}</span>
      <v-edit-dialog
        v-if="!disabled"
        large
        persistent
        @save="saveEdit('nr_override', item, edit_table_value)"
        @open="edit_table_value = item.nr_override * 100"
      >
        <span class="editable-field">{{
          item.nr_override | percentage(1)
        }}</span>
        <template v-slot:input>
          <v-text-field
            v-model="edit_table_value"
            label="% NR"
            :rules="requiredNumberRules"
            class="text-right mt-4 text-h6 text-right"
            suffix="%"
            style="width:180px"
            outlined
            dense
            autofocus
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.nr_adjusted="{ item }">
      {{ item.nr_adjusted | currency }}
    </template>
    <template v-slot:item.inc_nr="{ item }">
      {{ item.inc_nr | currency }}
    </template>
    <template v-slot:item.inc_nr_fc_sales="{ item }">
      {{ item.inc_nr_fc_sales | percentage(1) }}
    </template>
    <template v-slot:item.avg_value_sales_yr1="{ item }">
      {{ item.avg_value_sales_yr1 | currency }}
    </template>
    <template v-slot:item.max_value_sales_yr1="{ item }">
      {{ item.max_value_sales_yr1 | currency }}
    </template>
    <template v-slot:item.avg_dist_max_yr1="{ item }">
      {{ item.avg_dist_max_yr1 | numberFormat }}
    </template>
    <template v-slot:item.max_dist_max_yr1="{ item }">
      {{ item.max_dist_max_yr1 | numberFormat }}
    </template>
    <template v-slot:group.header="{ items, isOpen, toggle }">
      <th colspan="16">
        <v-icon @click="toggle"
          >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
        {{ items[0].country }}
      </th>
    </template>
    <!-- GROUP SUMMARY -->
    <template v-slot:group.summary="{ items, isOpen }">
      <template v-if="isOpen">
        <td class="text-start">Totals</td>
        <td class="text-end">
          {{ sumField('units_forecast', items) | currency }}
        </td>
        <td class="text-end">
          {{ sumField('value_forecast', items) | currency }}
        </td>
        <td class="text-end">&nbsp;</td>
        <td class="text-end">
          {{ sumField('adjusted_value_forecast', items) | currency }}
        </td>
        <td class="text-end">&nbsp;</td>
        <td class="text-end">&nbsp;</td>
        <td class="text-end">{{ sumField('nr_forecast') | currency }}</td>
        <td class="text-end">{{ sumField('nr_adjusted') | currency }}</td>
        <td class="text-end">{{ sumField('inc_nr') | currency }}</td>
        <td class="text-end">&nbsp;</td>
        <!-- <td class="text-end">
          {{ sumField('avg_value_sales_yr1') | currency }}
        </td>
        <td class="text-end">
          {{ sumField('max_value_sales_yr1') | currency }}
        </td>
        <td class="text-end">&nbsp;</td>
        <td class="text-end">&nbsp;</td> -->
      </template>
    </template>
    <!-- BASE COUNTRY -->
    <template slot="body.prepend">
      <tr class="v-row-group__header">
        <td class="text-start" colspan="15">
          Bases Test Country:
          {{ test_country.country || '-' }}
        </td>
      </tr>
      <tr
        v-for="(item, index) in test_country.skus"
        :key="index"
        class="base-country"
      >
        <td class="text-start">{{ item.sku_name }}</td>
        <td class="text-end">
          {{ item.units_forecast | currency }}
        </td>
        <td class="text-end">{{ item.value_forecast | currency }}</td>
        <td class="text-center">{{ item.price_override | percentage(1) }}</td>
        <td class="text-end">
          {{ item.adjusted_value_forecast | currency }}
        </td>
        <td class="text-center">{{ item.nr_percentage | percentage(1) }}</td>
        <td class="text-center">{{ item.nr_override | percentage }}</td>
        <td class="text-end">{{ item.nr_forecast | currency }}</td>
        <td class="text-end">{{ item.nr_adjusted | currency }}</td>
        <td class="text-end">{{ item.inc_nr | currency }}</td>
        <td class="text-center">{{ item.inc_nr_fc_sales | percentage }}</td>
        <!-- <td class="text-end">
          {{ item.avg_value_sales_yr1 | currency }}
        </td>
        <td class="text-end">
          {{ item.max_value_sales_yr1 | currency }}
        </td>
        <td class="text-center">{{ item.avg_dist_max_yr1 | numberFormat }}</td>
        <td class="text-center">
          {{ item.max_distribution_yr1 | numberFormat }}
        </td> -->
      </tr>
      <tr class="base-country base-country-totals">
        <td class="text-start">Totals</td>
        <td class="text-end">
          {{ sumField('units_forecast', test_country.skus) | currency }}
        </td>
        <td class="text-end">
          {{ sumField('value_forecast', test_country.skus) | currency }}
        </td>
        <td>&nbsp;</td>
        <td class="text-end">
          {{
            sumField('adjusted_value_forecast', test_country.skus) | currency
          }}
        </td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td class="text-end">
          {{ sumField('nr_forecast', test_country.skus) | currency }}
        </td>
        <td class="text-end">
          {{ sumField('nr_adjusted', test_country.skus) | currency }}
        </td>
        <td class="text-end">
          {{ sumField('inc_nr', test_country.skus) | currency }}
        </td>
        <td>&nbsp;</td>
        <!-- <td class="text-end">
          {{ sumField('avg_value_sales_yr1', test_country.skus) | currency }}
        </td>
        <td class="text-end">
          {{ sumField('max_value_sales_yr1', test_country.skus) | currency }}
        </td>
        <td>&nbsp;</td>
        <td>&nbsp;</td> -->
      </tr>
    </template>
    <!-- GLOBAL SUMMARY -->
    <template slot="body.append">
      <tr>
        <th class="table-summary">Totals</th>
        <th class="table-summary text-end">
          {{ sumField('units_forecast') | currency }}
        </th>
        <th class="table-summary text-end">
          {{ sumField('value_forecast') | currency }}
        </th>
        <th>&nbsp;</th>
        <th class="table-summary text-end">
          {{ sumField('adjusted_value_forecast') | currency }}
        </th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th class="table-summary text-end">
          {{ sumField('nr_forecast') | currency }}
        </th>
        <th class="table-summary text-end">
          {{ sumField('nr_adjusted') | currency }}
        </th>
        <th class="table-summary text-end">
          {{ sumField('inc_nr') | currency }}
        </th>
        <th>&nbsp;</th>
        <!-- <th class="table-summary text-end">
          {{ sumField('avg_value_sales_yr1') | currency }}
        </th>
        <th class="table-summary text-end">
          {{ sumField('max_value_sales_yr1') | currency }}
        </th>
        <th>&nbsp;</th>
        <th>&nbsp;</th> -->
      </tr>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'ForecastTable',
  props: {
    value: {
      type: Object,
      default() {
        return { forecast_data: [], test_country: { country: null, skus: [] } };
      },
    },
    loading: {
      type: Boolean,
      default() {
        false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        false;
      },
    },
  },
  data() {
    // const header_center = { groupable: false, align: 'center' };
    const header_currency = { groupable: false, align: 'end', width: '135px' };
    const header_percentage = {
      groupable: false,
      align: 'center',
      width: '135px',
    };

    return {
      forecast: this.value.forecast_data,
      test_country: this.value.test_country,
      headers: [
        { text: 'Country', value: 'country' },
        { text: 'SKU Name (details)', value: 'sku_name', width: '250px' },
        {
          text: 'Units Forecast',
          value: 'units_forecast',
          ...header_currency,
        },
        {
          text: 'Value Forecast Price',
          value: 'value_forecast',
          cellClass: 'editable-field',
          class: 'editable-field',
          ...header_currency,
          width: '165px',
        },
        {
          text: 'Price Override',
          value: 'price_override',
          cellClass: 'editable-field',
          class: 'editable-field',
          ...header_percentage,
        },
        {
          text: 'Adjusted Value Forecast',
          value: 'adjusted_value_forecast',
          ...header_currency,
        },
        {
          text: '% NR',
          value: 'nr_percentage',
          ...header_percentage,
        },
        {
          text: '% NR Override',
          value: 'nr_override',
          cellClass: 'editable-field',
          ...header_percentage,
        },
        { text: 'NR Forecast', value: 'nr_forecast', ...header_currency },
        { text: 'NR Adjusted', value: 'nr_adjusted', ...header_currency },
        { text: 'INC NR', value: 'inc_nr', ...header_currency },
        {
          text: 'INC NR% F/C sales',
          value: 'inc_nr_fc_sales',
          ...header_percentage,
          width: '120px',
        },
        // {
        //   text: 'Avg Value Sales YR1',
        //   value: 'avg_value_sales_yr1',
        //   ...header_currency,
        // },
        // {
        //   text: 'Max Value Sales YR1',
        //   value: 'max_value_sales_yr1',
        //   ...header_currency,
        // },
        // {
        //   text: 'Avg Dist. Max YR1',
        //   value: 'avg_dist_max_yr1',
        //   ...header_center,
        //   width: '150px',
        // },
        // {
        //   text: 'Max Dist. YR1',
        //   value: 'max_distribution_yr1',
        //   ...header_center,
        //   width: '130px',
        // },
      ],
      edit_table_value: null,
    };
  },
  watch: {
    value() {
      this.forecast = this.value.forecast_data;
      this.test_country = this.value.test_country;
    },
  },
  methods: {
    sumField(prop, items) {
      const source = items ? items : this.forecast;

      return source.reduce((previous, current) => {
        return previous + (parseFloat(current[prop]) || 0);
      }, 0);
    },
    saveEdit(field, item, value) {
      item[field] = value / 100;
      this.$emit('update', item);
    },
  },
};
</script>
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
.v-row-group__summary {
  background: transparent !important;
  font-style: italic;
  font-weight: bold;
  color: rgba(70, 73, 77, 0.8);
}
.table-summary {
  font-size: 15px !important;
}

.base-country {
  font-style: italic;
  background: var(--v-accent-lighten4);
  color: var(--v-info-darken4) !important;
}

.base-country-totals {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
}
.text-right {
  text-align: right;
  text-align-last: right;
}
.editable-field {
  color: var(--v-warning-darken1);
}
</style>
