<template>
  <main-layout>
    <v-stepper v-model="projectForm.step" vertical>
      <h2 class="font-weight-regular ml-5 mt-5 mb-5">{{ title }}</h2>
      <v-container fluid class="py-0 px-0">
        <v-row>
          <v-col cols="4" class="ml-5">
            <v-text-field
              v-model="projectForm.name"
              label="Project Name"
              :rules="projectNameRules"
              persistent-placeholder
              placeholder="Please write a name"
              :disabled="readOnlyProject"
              persistent-hint
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="ml-5">
            <v-text-field
              v-model="nielsenDate"
              label="Nielsen GTC Date"
              outlined
              dense
              prepend-icon="mdi-calendar"
              class="center-text"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <!-- STEP 1 -->

      <v-stepper-step
        class="stepper-step"
        :complete="projectForm.step > 1"
        step="1"
        :editable="allowToContinue(1)"
      >
        Parameters
      </v-stepper-step>
      <v-stepper-content step="1" ref="stepParameters">
        <v-container>
          <v-row>
            <v-col cols="8">
              <hierarchy-filters
                v-model="parameters"
                :dataSource="dataSource"
                :disabled="readOnlyProject"
              ></hierarchy-filters>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col cols="8">
              <v-row justify="center">
                <v-btn
                  color="primary"
                  class="ml-5"
                  @click="projectForm.step = 2"
                  :disabled="!validateParameters"
                >
                  Continue
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
      <!-- STEP 2 -->
      <v-stepper-step
        :complete="projectForm.step > 2"
        step="2"
        :editable="allowToContinue(2)"
        >Test Country</v-stepper-step
      >
      <v-stepper-content step="2">
        <v-container fluid class="py-0 px-0">
          <v-row>
            <v-col cols="8" class="pl-4">
              <test-country
                v-model="testCountryField"
                :list="testCountryList"
                :disabled="readOnlyProject"
              ></test-country>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" class="mb-5">
              <v-row justify="center">
                <v-btn
                  color="primary"
                  class="ml-5"
                  @click="projectForm.step = 3"
                  :disabled="!validateTestCountry"
                >
                  Continue
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
      <!-- STEP 3 -->
      <v-stepper-step
        :complete="projectForm.step > 3"
        step="3"
        :editable="allowToContinue(3)"
        >Bases Input</v-stepper-step
      >
      <v-stepper-content step="3">
        <v-container fluid class="py-0 px-0 mb-2">
          <v-row no-gutters>
            <v-col cols="8">
              <bases-input
                v-model="basesInputField"
                :disabled="readOnlyProject"
              ></bases-input>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col cols="8">
              <v-row justify="center">
                <v-btn
                  color="primary"
                  class="ml-5"
                  @click="projectForm.step = 4"
                  :disabled="!validateBasesInput"
                >
                  Continue
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
      <!-- STEP 4 -->
      <v-stepper-step
        :complete="projectForm.step > 4"
        step="4"
        :editable="allowToContinue(4)"
        >NPD SKU Details</v-stepper-step
      >
      <v-stepper-content step="4">
        <v-container fluid class="py-0 px-0 mb-2">
          <v-row>
            <v-col cols="8">
              <npd-sku-details
                v-model="projectForm.sku_rows"
                :disabled="readOnlyProject"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-5 mb-5">
            <v-col cols="8">
              <v-row justify="center">
                <v-btn
                  color="primary"
                  class="ml-5"
                  @click="goToForecast"
                  :disabled="!validateSkuDetails"
                >
                  Continue
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
      <!-- STEP 5 -->
      <v-stepper-step
        :complete="projectForm.status == 2"
        step="5"
        :editable="allowToContinue(5)"
      >
        Forecast
      </v-stepper-step>

      <v-stepper-content step="5" ref="stepForecast">
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-select
                v-model="forecastBasisField"
                :items="forecastBasisList"
                item-value="key"
                item-text="value"
                label="Forecast Basis"
                hide-details
                placeholder="Select forecast basis"
                :disabled="loadingForecast || readOnlyProject"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <forecast-table
                v-model="forecastData"
                :loading="loadingForecast"
                :disabled="readOnlyProject"
                @update="forecastTableUpdate"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-row justify="center" class="mt-5">
                <v-btn
                  v-if="!showToExportExcel"
                  color="success"
                  class="ml-5"
                  @click="confirmFinalize = true"
                  :disabled="!validateFinalize"
                >
                  <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
                  Finalize project
                </v-btn>
                <v-btn
                  v-if="showToExportExcel"
                  color="green darken-1"
                  class="ml-3"
                  outlined
                  :loading="exportingToExcel"
                  :disabled="!allowToExportExcel"
                  @click="exportToExcel(forecastPerCountry)"
                  ><v-icon left>mdi-file-excel</v-icon>Export to Excel</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>

      <v-container>
        <v-row class="mt-5"><v-divider class="mr-5 ml-15"></v-divider></v-row>
        <v-row justify="space-between" class="ml-3 mt-5">
          <v-col offset="6" cols="6">
            <v-row justify="end" class="mr-8">
              <v-btn
                color="red darken-3"
                class="mr-3  button"
                outlined
                hidden
                :disabled="!allowToDelete"
                @click="confirmDelete = true"
                ><v-icon left>mdi-trash-can-outline</v-icon>Delete</v-btn
              >
              <v-btn
                class="mr-3"
                color="blue button"
                outlined
                :disabled="!allowToNew"
                @click="confirmNew = true"
                ><v-icon left>mdi-shape-rectangle-plus</v-icon> New</v-btn
              >
              <v-btn
                color="success button"
                :loading="storing"
                :disabled="!allowToSave"
                outlined
                @click="save"
                ><v-icon left>mdi-content-save-outline</v-icon>Save</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-stepper>
    <confirmation-message
      key="confirmationNew"
      :show="confirmNew"
      icon="warning"
      title="New Project"
      :message="
        `Are you sure you want to continue?.\n All unsaved changes will be lost.`
      "
      button1-text="Cancel"
      @button1="confirmNew = false"
      button2-text="New Project"
      button2-color="info"
      button2-type="solid"
      @button2="newProject"
    />
    <confirmation-message
      key="confirmationDelete"
      :show="confirmDelete"
      icon="warning"
      title="Delete permanently"
      :message="
        `Are you sure you want to delete the project '${projectForm.name}'?.\n This action cannot not be undone.`
      "
      button1-text="Cancel"
      @button1="confirmDelete = false"
      button2-text="Delete"
      button2-color="error darken-1"
      button2-type="solid"
      @button2="deleteCurrentProject"
    />
    <confirmation-message
      key="confirmationFinalize"
      :show="confirmFinalize"
      icon="warning"
      title="Finalize Project"
      :message="
        `You are about to finilize your project '${projectForm.name}'?.\n After that is not possible to make any changes.\n Do you want to continue?`
      "
      button1-text="Cancel"
      @button1="confirmFinalize = false"
      button2-text="Finalize"
      button2-color="success"
      button2-type="solid"
      @button2="finalize"
    />
  </main-layout>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api';

import HierarchyFilters from '@/components/custom/HierarchyFilters';
import TestCountry from '@/components/forecast/partials/TestCountry';
import BasesInput from '@/components/forecast/partials/BasesInput';
import NpdSkuDetails from '@/components/forecast/partials/SkuTable';
import ForecastTable from '@/components/forecast/partials/ForecastTable';
import ConfirmationMessage from '@/components/common/ConfirmationMessage';
import { catalog, project } from '@/store-mapping';
import { createProjectStructure } from '@/global/projectModel';
import _ from 'lodash';

export default {
  components: {
    HierarchyFilters,
    TestCountry,
    BasesInput,
    NpdSkuDetails,
    ForecastTable,
    ConfirmationMessage,
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, ctx) {
    const { getAll, state: catalogState } = catalog;
    const {
      projectState,
      calculateForecast,
      setProject,
      storeProject,
      loadProject,
      deleteProject,
      createNewProject,
      exportForecatToExcel,
    } = project;

    const title = ref('Forcast Project');

    const stepParameters = ref(null);
    const stepForecast = ref(null);

    const confirmDelete = ref(false);
    const confirmFinalize = ref(false);
    const confirmNew = ref(false);
    const validForm = ref(false);
    const creatingProject = ref(false); //NOT NEED IT??

    const forecastData = ref({
      forecast_data: [],
      test_country: {
        country: null,
        skus: [],
      },
    });
    const projectForm = ref(createProjectStructure());

    //-------------------COMPUTED-----------------------

    const forecast = computed(() => projectState.forecast); //store.state.project.forecast);
    const dataSource = computed(() => catalogState.parameters);
    const loadingForecast = computed(() => projectState.loadingForecast);
    const nielsenDate = computed(() => {
      if (!dataSource.value.dump_date) return '';
      projectForm.value.dump_date = dataSource.value.dump_date;
      var pattern = /(\d{4})(\d{2})(\d{2})/;
      return dataSource.value.dump_date.replace(pattern, '$3-$2-$1');
    });
    const loadingCatalogs = computed(() => catalogState.loading);
    const storing = computed(() => projectState.storing);
    const exportingToExcel = computed(() => projectState.exportingToExcel);
    const forecastParameters = computed(() => {
      const pm = projectForm.value;
      return {
        dump_date: pm.dump_date,
        category: pm.category,
        segment: pm.segment,
        brand: pm.brand,
        test_country: pm.test_country,
        sova: pm.sova,
        bases_volume_forecast: pm.bases_volume_forecast,
        forecast_basis: pm.forecast_basis,
        selected_countries: pm.selected_countries,
        sku_rows: pm.sku_rows,
      };
    });

    const parameters = computed({
      get: () => ({
        category: projectForm.value.category,
        segment: projectForm.value.segment,
        brand: projectForm.value.brand,
        countries: projectForm.value.selected_countries.map(sc => sc.country),
      }),
      set: newValue => {
        projectForm.value.category = newValue.category;
        projectForm.value.segment = newValue.segment;
        projectForm.value.brand = newValue.brand;

        projectForm.value.selected_countries = [];
        newValue.countries.forEach(c => {
          projectForm.value.selected_countries.push({
            country: c,
            price_override: 0.0,
            nr_percentage: 0.5,
            nr_override: 0.0,
          });
        });
        if (!newValue.countries.includes(projectForm.value.test_country))
          projectForm.value.test_country = null;
      },
    });

    const testCountryField = computed({
      get: () => ({
        test_country: projectForm.value.test_country,
        bases_concept_id: projectForm.value.bases_concept_id,
        plan_distribution: projectForm.value.plan_distribution,
        plan_marketing_spend: projectForm.value.plan_marketing_spend,
      }),
      set: newValue => {
        projectForm.value.test_country = newValue.test_country;
        projectForm.value.bases_concept_id = newValue.bases_concept_id;
        projectForm.value.plan_distribution = newValue.plan_distribution;
        projectForm.value.plan_marketing_spend = newValue.plan_marketing_spend;
      },
    });

    const basesInputField = computed({
      get: () => ({
        cps: projectForm.value.cps,
        trial_tried_product: projectForm.value.trial_tried_product,
        trial_packs_purchased: projectForm.value.trial_packs_purchased,
        repeat_pack_buy_each: projectForm.value.repeat_pack_buy_each,
        repeat_purchases_per_hh: projectForm.value.repeat_purchases_per_hh,
        repeat_at_least_once: projectForm.value.repeat_at_least_once,
        sova: projectForm.value.sova,
        fga_volume: projectForm.value.fga_volume,
        fga_value: projectForm.value.fga_value,
        bases_volume_forecast: projectForm.value.bases_volume_forecast,
      }),
      set: newValue => {
        projectForm.value.cps = newValue.cps;
        projectForm.value.trial_tried_product = newValue.trial_tried_product;
        projectForm.value.trial_packs_purchased =
          newValue.trial_packs_purchased;
        projectForm.value.repeat_pack_buy_each = newValue.repeat_pack_buy_each;
        projectForm.value.repeat_purchases_per_hh =
          newValue.repeat_purchases_per_hh;
        projectForm.value.repeat_at_least_once = newValue.repeat_at_least_once;
        projectForm.value.sova = newValue.sova;
        projectForm.value.fga_volume = newValue.fga_volume;
        projectForm.value.fga_value = newValue.fga_value;
        projectForm.value.bases_volume_forecast =
          newValue.bases_volume_forecast;
      },
    });

    const testCountryList = computed(() =>
      projectForm.value.selected_countries.map(sc => sc.country)
    );

    const forecastBasisField = computed({
      get: () => projectForm.value.forecast_basis,
      set: value => {
        projectForm.value.forecast_basis = value;
      },
    });

    const forecastBasisList = computed(() => ['Category', 'Segment']);

    const loadForecast = () => {
      const fp = forecastParameters.value;
      if (
        fp.dump_date != null &&
        fp.category != null &&
        fp.segment != null &&
        fp.brand != null &&
        fp.test_country != null &&
        fp.sova != null &&
        fp.bases_volume_forecast != null &&
        !_.isEmpty(fp.forecast_basis) &&
        fp.selected_countries != null &&
        fp.selected_countries.length > 0 &&
        fp.sku_rows != null &&
        fp.sku_rows.length > 0
      ) {
        calculateForecast(forecastParameters.value);
      }
    };

    const forecastPerCountry = computed(() => {
      const groupBy = (xs, key) => {
        return xs.reduce(function(pv, cv) {
          (pv[cv[key]] = pv[cv[key]] || []).push(cv);
          delete cv[key];
          return pv;
        }, {});
      };

      const grouped = groupBy(forecastData.value.forecast_data, 'country');

      return {
        forecastData: Object.keys(grouped).map(k => ({
          country: k,
          skus: grouped[k],
        })),
        test_country: forecastData.value.test_country,
      };
    });

    //-------------------WATCHS-----------------------

    watch(
      () => projectForm.value.step,
      () => {
        const className = ' stepper-hotfix';
        stepParameters.value.$el.className = stepParameters.value.$el.className.replace(
          className,
          ''
        );
        stepForecast.value.$el.className = stepForecast.value.$el.className.replace(
          className,
          ''
        );

        if (projectForm.value.step == 1)
          setTimeout(
            () => (stepParameters.value.$el.className += className),
            500
          );
        if (projectForm.value.step == 5)
          setTimeout(
            () => (stepForecast.value.$el.className += className),
            500
          );
      }
    );

    watch(forecast, newValue => {
      if (!newValue) return;
      const flatData = newValue.forecast_data.flatMap(i =>
        i.skus.map(x => {
          return { ...x, country: i.country };
        })
      );

      forecastData.value = {
        forecast_data: flatData,
        test_country: forecast.value.test_country, //CHECK HERE
      };
    });

    watch(forecastBasisField, () => {
      setTimeout(loadForecast, 500);
    });

    watch(
      () => projectForm.value.name,
      value => {
        if (projectForm.value.step > 1) return;
        if (!_.isEmpty(value)) {
          projectForm.value.step = 1;
        } else {
          projectForm.value.step = 0;
        }
      }
    );

    //-------------------VALIDATIONS-----------------------

    const validateParameters = computed(() => {
      const pa = parameters.value;
      return (
        pa.category != null &&
        pa.segment != null &&
        pa.brand != null &&
        pa.countries != null &&
        pa.countries.length > 1
      );
    });

    const validateTestCountry = computed(() => {
      const tc = testCountryField.value;
      return (
        tc.test_country != null &&
        tc.bases_concept_id != null &&
        tc.plan_distribution != null &&
        tc.plan_distribution > 0 &&
        tc.plan_marketing_spend != null &&
        tc.plan_marketing_spend > 0
      );
    });

    const validateBasesInput = computed(() => {
      const bi = basesInputField.value;
      return (
        bi.cps != null &&
        bi.cps > 0 &&
        bi.trial_tried_product != null &&
        bi.trial_tried_product > 0 &&
        bi.trial_packs_purchased != null &&
        bi.trial_packs_purchased > 0 &&
        bi.repeat_pack_buy_each != null &&
        bi.repeat_pack_buy_each > 0 &&
        bi.repeat_purchases_per_hh != null &&
        bi.repeat_purchases_per_hh > 0 &&
        bi.repeat_at_least_once != null &&
        bi.repeat_at_least_once > 0 &&
        bi.sova != null &&
        bi.sova > 0 &&
        bi.fga_volume != null &&
        bi.fga_volume > 0 &&
        bi.fga_value != null &&
        bi.fga_value > 0 &&
        bi.bases_volume_forecast != null &&
        bi.bases_volume_forecast > 0
      );
    });

    const validateSkuDetails = computed(() => {
      return (
        projectForm.value.sku_rows.reduce(
          (p, v) => p + parseFloat(v.volume_split_ratio),
          0
        ) == 1
      );
    });

    const validateFinalize = computed(() => {
      const pm = projectForm.value;
      return (
        !_.isEmpty(pm.forecast_basis) &&
        !loadingForecast.value &&
        pm.status == 1
      );
    });

    const allowToSave = computed(() => {
      const pf = projectForm.value;
      return !_.isEmpty(pf.name) && pf.status == 1;
    });

    const showToExportExcel = computed(() => {
      return projectForm.value.status == 2;
    });

    const allowToExportExcel = computed(() => {
      return projectForm.value.status == 2 && !loadingForecast.value;
    });

    const readOnlyProject = computed(() => projectForm.value.status == 2);
    //-------------------------------------------------------
    //---------------METHODS----------------------------------
    const goToForecast = () => {
      projectForm.value.step = 5;
      loadForecast();
    };

    const forecastTableUpdate = item => {
      const sku = projectForm.value.selected_countries.find(
        sc => sc.country == item.country
      );
      sku.price_override = item.price_override;
      sku.nr_override = item.nr_override;
      calculateForecast(forecastParameters.value);
    };

    const deleteCurrentProject = () =>
      deleteProject().then(() => {
        confirmDelete.value = false;
        ctx.root.$router.push('/');
      });

    const allowToDelete = computed(
      () => projectForm.value.status == 1 && projectForm.value.id > 0
    );

    const allowToNew = () => {
      return true;
    };
    const allowToContinue = nextStep => {
      switch (nextStep) {
        case 1:
          return !_.isEmpty(projectForm.value.name);
        case 2:
          return validateTestCountry.value;
        case 3:
          return validateBasesInput.value;
        case 4:
        case 5:
          return validateSkuDetails.value;
      }

      return false;
    };

    const createProject = async () => {
      creatingProject.value = true;

      await createNewProject();

      setProject({
        name: projectForm.value,
      });

      storeProject().then(data => {
        ctx.root.$router.push(
          `/project/${data.projectId}`,
          null,
          () => (creatingProject.value = false)
        );
      });
    };

    const newProject = async () => {
      ctx.root.$router.push('/forecast/wizard');
      projectForm.value = createProjectStructure();
      setProject(projectForm.value);
      confirmNew.value = false;
    };

    const save = async () => {
      setProject(projectForm.value);

      storeProject()
        .then(data => {
          if (data && data.id) projectForm.value.id = data.id;

          ctx.root.$notify({
            title: title.value,
            text:
              `Project ${projectForm.value.name} saved! ` +
              (projectForm.value.id ? `(id:${projectForm.value.id})` : ''),
            type: 'success',
          });
        })
        .catch(() => {
          ctx.root.$notify({
            title: title.value,
            text: `It was not possible to save the project!`,
            type: 'error',
          });
        });
    };

    const cancel = () => {
      ctx.root.$router.push('/guidedanalysis/list');
    };

    const exportToExcel = data => exportForecatToExcel(data);
    //-------------------------------------------------------

    onMounted(async () => {
      await getAll();
      if (ctx.root.$route.params && ctx.root.$route.params.projectId) {
        const id = ctx.root.$route.params.projectId;
        loadProject(id).then(() => {
          projectForm.value = _.cloneDeep(projectState.project);
        });
      }
    });

    const finalize = () => {
      //ask
      projectForm.value.status = 2; //Finalize
      save().then(() => (confirmFinalize.value = false));
    };

    return {
      title,
      validForm,
      creatingProject,
      nielsenDate,
      allowToContinue,
      allowToNew,
      allowToDelete,
      createProject,
      cancel,
      exportToExcel,

      dataSource,

      loadingCatalogs,

      validateParameters,
      validateTestCountry,
      validateBasesInput,
      validateSkuDetails,
      validateFinalize,
      allowToSave,
      finalize,
      projectForm,
      parameters,
      testCountryList,
      testCountryField,
      basesInputField,
      forecastBasisField,
      loadingForecast,
      forecastData,
      newProject,
      storing,
      forecastBasisList,
      goToForecast,
      forecastTableUpdate,
      save,
      allowToExportExcel,
      exportingToExcel,
      showToExportExcel,
      forecastPerCountry,
      confirmNew,
      confirmDelete,
      confirmFinalize,
      deleteCurrentProject,
      readOnlyProject,
      stepParameters,
      stepForecast,
    };
  },
};
</script>

<style lang="scss" scoped>
.project-name {
  width: 400px;
}
.data-source {
  width: 500px;
}
.fact {
  width: 300px;
}
.outlook {
  width: 500px;
}
.center-text {
  // font-style: italic;
  text-align: center;
  text-align-last: center;
  color: blue !important;
}

// .center-text::v-deep .success--text,
// .center-text::v-deep .v-input__prepend-outer,
.center-text::v-deep .v-label,
.center-text::v-deep i,
.center-text::v-deep input,
.center-text::v-deep fieldset {
  color: #78909c !important;
  caret-color: #78909c !important;
  border-width: 1px !important;
}

// .center-text::v-deep .success--text {
//   color: red !important;
//   opacity: 1;
// }

.center-text .v-label {
  color: red;
  opacity: 1;
}

.stepper-hotfix::v-deep .v-stepper__wrapper {
  height: auto !important;
}
</style>
