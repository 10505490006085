<template>
  <v-card outlined>
    <v-container class="ml-5">
      <v-row>
        <v-col cols="5">
          <v-select
            v-model="form.test_country"
            :items="list"
            item-value="key"
            item-text="value"
            label="Test Country"
            :disabled="disabled"
            hide-details
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col class="offset-1" cols="5">
          <v-text-field
            v-model.number="form.bases_concept_id"
            label="Bases Concept ID"
            :rules="requiredNumberRules"
            class="text-right"
            placeholder="1234567890"
            persistent-placeholder
            :disabled="disabled"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="11" class="pb-2">
          <span>Plan</span>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model.number="plan_marketing_spend"
            label="Marketing Spend"
            :rules="requiredNumberRules"
            style="width: 280px"
            placeholder="0.0"
            persistent-placeholder
            :disabled="disabled"
            prefix="£"
            suffix="million(s)"
            class="text-right"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="5" class="offset-1">
          <v-text-field
            v-model.number="form.plan_distribution"
            label="Distribution (end Year ACV)"
            :rules="requiredNumberRules"
            :disabled="disabled"
            placeholder="0.0"
            persistent-placeholder
            class="text-right"
            suffix="%"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row> </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api';

export default {
  name: 'TestCountry',
  props: {
    list: Array,
    value: Object,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const form = ref({
      test_country: props.value.test_country,
      bases_concept_id: props.value.bases_concept_id,
      plan_distribution: props.value.plan_distribution,
      plan_marketing_spend: props.value.plan_marketing_spend,
    });

    const plan_marketing_spend = computed({
      get: () => {
        if (form.value.plan_marketing_spend)
          return form.value.plan_marketing_spend / 1000000;
      },
      set: value => (form.value.plan_marketing_spend = value * 1000000),
    });

    watch(
      () => props.value,
      v => {
        form.value.test_country = v.test_country;
        form.value.bases_concept_id = v.bases_concept_id;
        form.value.plan_distribution = v.plan_distribution;
        form.value.plan_marketing_spend = v.plan_marketing_spend;
      }
    );

    watch(form, () => emit('input', form.value), { deep: true });

    return {
      form,
      plan_marketing_spend,
    };
  },
};
</script>

<style></style>
