<template>
  <v-container fluid fill-height class="pb-0">
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="skus"
          disable-pagination
          hide-default-footer
          sort-by="calories"
          class="elevation-1"
          style="width: 100%;"
        >
          <!-- TOP BAR - ADD NEW ITEMS -->
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    :disabled="disabled"
                    v-on="on"
                  >
                    New SKU
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container class="pb-0">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.sku_name"
                            label="Name (Details)"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.launch_name"
                            label="Launch Name"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model.number="editedItem.volume_split_ratio"
                            label="Volume split ratio (Bases)"
                            type="number"
                            class="text-right"
                            suffix="%"
                            outlined
                            dense
                          >
                            <template v-slot:append>
                              <v-progress-circular
                                :value="editedItem.volume_split_ratio"
                                size="25"
                                color="info"
                              ></v-progress-circular>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model.number="editedItem.launch_price"
                            label="Launch Price"
                            class="text-right"
                            prefix="£"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                          <h3>
                            Total volume split ratio:
                            {{ (editedTotalSplitRatio / 100) | percentage(1) }}
                            <v-progress-circular
                              class="mb-1"
                              :value="editedTotalSplitRatio"
                              size="25"
                              :color="
                                calcSplitRatioColor(editedTotalSplitRatio / 100)
                              "
                            ></v-progress-circular>
                          </h3>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      @click="save"
                      :disabled="!isValidSplitRatio(editedTotalSplitRatio)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <confirmation-message
                key="pendingChanges"
                :show="dialogDelete"
                icon="warning"
                title="SKU Delete confirmation"
                message="Are you sure you want to delete the SKU?"
                button1-text="Discard"
                @button1="deleteItemConfirm"
                button2-text="Cancel"
                @button2="dialogDelete = false"
              />
            </v-toolbar>
          </template>
          <!------------------------------->
          <!-- ITEMS -->
          <template v-slot:item.volume_split_ratio="{ item }">
            <v-container>
              <v-row no-gutters>
                <v-col offset="4" cols="3">
                  {{ item.volume_split_ratio | percentage(1) }}
                </v-col>
                <v-col cols="1">
                  <v-progress-circular
                    :value="item.volume_split_ratio"
                    size="25"
                    color="info"
                    style="top:-2px"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.launch_price="{ item }">
            {{ item.launch_price | currency }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon v-if="!disabled" small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon v-if="!disabled" small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            Add a new SKU
          </template>

          <template slot="body.append">
            <tr class="table-summary">
              <th>Total of products: {{ totalNumProducts }}</th>
              <th class="text-center">
                <v-container>
                  <v-row no-gutters>
                    <v-col offset="4" cols="3">
                      {{ totalSplitRatio | percentage(1) }}
                    </v-col>
                    <v-col cols="1">
                      <v-progress-circular
                        :value="totalSplitRatio * 100"
                        class="ml-3"
                        style="top:-2px"
                        size="25"
                        :color="calcSplitRatioColor(totalSplitRatio)"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-container>
              </th>
              <th></th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </v-col> </v-row
  ></v-container>
</template>
<script>
import ConfirmationMessage from '@/components/common/ConfirmationMessage';

export default {
  name: 'skuTable',
  props: {
    value: Array,
    default: [],
    disabled: Boolean,
  },
  components: {
    ConfirmationMessage,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'SKU Name (details)', value: 'sku_name', align: 'left' },
        { text: 'SKU Launch Name', value: 'launch_name', align: 'left' },
        {
          text: 'SKUs volume split ratio (Bases)',
          value: 'volume_split_ratio',
          align: 'center',
        },
        { text: 'Launch Price', value: 'launch_price', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      skus: this.value,
      editedIndex: -1,
      editedItem: {
        sku_name: '',
        launch_name: '',
        volume_split_ratio: 0,
        launch_price: 0,
      },
      defaultItem: {
        sku_name: '',
        launch_name: '',
        volume_split_ratio: 0,
        launch_price: 0,
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New SKU' : 'Edit SKU';
    },
    totalNumProducts() {
      return this.skus.length;
    },
    totalSplitRatio() {
      return this.skus.reduce(
        (p, v) => p + parseFloat(v.volume_split_ratio),
        0
      );
    },
    editedTotalSplitRatio() {
      return (
        this.skus
          .filter(f => f.sku_name != this.editedItem.sku_name)
          .reduce((p, v) => p + parseFloat(v.volume_split_ratio), 0) *
          100 +
        this.editedItem.volume_split_ratio
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    value(v) {
      this.skus = v;
    },
  },

  methods: {
    calcSplitRatioColor(ratio) {
      if (ratio > 1) return 'red';
      return ratio < 1 ? 'warning' : 'green';
    },
    isValidSplitRatio(ratio) {
      return ratio > 0 && ratio <= 100;
    },
    editItem(item) {
      this.editedIndex = this.skus.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.volume_split_ratio *= 100;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.skus.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.skus.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.editedItem.volume_split_ratio =
        this.editedItem.volume_split_ratio / 100;

      if (this.editedIndex > -1) {
        Object.assign(this.skus[this.editedIndex], this.editedItem);
      } else {
        this.skus.push(this.editedItem);
      }
      this.$emit('input', this.skus);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-right {
  text-align: right;
  text-align-last: right;
}
.table-summary > th {
  font-size: 14px !important;
}
.divider {
  border-color: var(--v-primary-base) !important;
}
</style>
