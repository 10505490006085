const state = {
  leftMenu: {
    show: true,
  },
  rightMenu: {
    title: '',
    show: false,
    width: 350,
  },
  fineTuning: false,
};

const RIGHT_MENU = 'RIGHT_MENU';
const LEFT_MENU = 'LEFT_MENU';
const FINE_TUNING = 'FINE_TUNING';

const actions = {
  showRightMenu({ commit }, show) {
    commit(RIGHT_MENU, { show });
  },
  setRightMenuSettings({ commit }, settings) {
    commit(RIGHT_MENU, settings);
  },
  showLeftMenu({ commit }, show) {
    commit(LEFT_MENU, { show });
  },
  setFineTuning({ commit }, enabled) {
    commit(FINE_TUNING, enabled);
  },
};

const mutations = {
  [RIGHT_MENU](state, settings) {
    state.rightMenu = {
      ...state.rightMenu,
      ...settings,
    };
  },
  [LEFT_MENU](state, settings) {
    state.leftMenu = {
      ...state.leftMenu,
      ...settings,
    };
  },
  [FINE_TUNING](state, fineTuning) {
    state.fineTuning = fineTuning;
  },
};

export const app = {
  namespaced: true,
  state,
  actions,
  mutations,
};
