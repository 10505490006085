<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-select
        label="Category"
        v-model="categorySelection"
        :items="categoryCatalog"
        @change="categorySelectionChange"
        item-text="name"
        return-object
        hide-details
        outlined
        :disabled="loadingCatalogs || disabled"
        dense
        :loading="loadingCatalogs"
      ></v-select>
    </v-col>
    <v-col cols="12">
      <v-select
        label="Segment"
        v-model="segmentSelection"
        :items="segmentCatalog"
        @change="segmentSelectionChange"
        item-text="name"
        return-object
        hide-details
        outlined
        :disabled="loadingCatalogs || disabled"
        dense
        :loading="loadingCatalogs"
      ></v-select>
    </v-col>
    <v-col cols="12">
      <v-select
        label="Brand"
        v-model="brandSelection"
        :items="brandCatalog"
        item-text="name"
        return-object
        hide-details
        outlined
        :disabled="loadingCatalogs || disabled"
        dense
        :loading="loadingCatalogs"
      ></v-select>
    </v-col>
    <v-col cols="12">
      <hierarchy-tree
        label="Market selection"
        v-model="marketSelection"
        :catalog="marketCatalog"
        :catalogLevels="marketCatalogLevels"
        :loading="loadingCatalogs"
        :disabled="loadingCatalogs || disabled"
      ></hierarchy-tree>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import HierarchyTree from './HierarchyTree';

export default {
  props: ['dataSource', 'disabled', 'value'],
  components: {
    HierarchyTree,
  },
  data() {
    return {
      categorySelection: null,
      segmentSelection: null,
      brandSelection: null,
      marketSelection: null,
      // Sync helpers
      filtersOutUnwatch: null,
      currentConfig: {
        category: this.value.category,
        segment: this.value.segment,
        brand: this.value.brand,
        marketDimension: this.value.countries,
      },
    };
  },
  computed: {
    ...mapState('catalog', {
      loadingCatalogs: state => state.loading,
    }),
    categoryCatalog() {
      return this.dataSource.categories;
    },
    segmentCatalog() {
      return this.categorySelection ? this.categorySelection.segments : [];
    },
    brandCatalog() {
      return this.segmentSelection ? this.segmentSelection.brands : [];
    },
    marketCatalog() {
      this.$emit('update');
      return this.brandSelection ? this.brandSelection.regions : [];
    },
    marketCatalogLevels() {
      return ['regions', 'countries'];
    },
    filtersOut() {
      const category = this.categorySelection
        ? this.categorySelection.name
        : null;
      const segment = this.segmentSelection ? this.segmentSelection.name : null;
      const brand = this.brandSelection ? this.brandSelection.name : null;

      return {
        category,
        segment,
        brand,
        ...this.marketSelection,
      };
    },
  },
  watch: {
    value(newValue) {
      this.currentConfig = {
        category: newValue.category,
        segment: newValue.segment,
        brand: newValue.brand,
        marketDimension: newValue.countries,
      };
    },
    dataSource(newDataSource) {
      this.loadCurrentConfiguration();
      this.updateSelections(newDataSource);
    },
  },
  created() {
    // this.loadCurrentConfiguration();
    // this.updateSelections(this.dataSource);
  },
  methods: {
    loadCurrentConfiguration() {
      this.categorySelection = null;
      this.segmentSelection = null;
      this.brandSelection = null;

      this.categorySelection = this.dataSource.categories.find(
        d => d.name == this.currentConfig.category
      );

      if (this.categorySelection != null) {
        this.segmentSelection = this.categorySelection.segments.find(
          d => d.name == this.currentConfig.segment
        );
      }

      if (this.segmentSelection != null) {
        this.brandSelection = this.segmentSelection.brands.find(
          d => d.name == this.currentConfig.brand
        );
      }

      this.marketSelection = {
        regions: [],
        countries: this.currentConfig.marketDimension,
      };
    },
    categorySelectionChange() {
      this.cleanUpSegment();
    },
    segmentSelectionChange() {
      this.cleanUpBrand();
    },
    async updateSelections(newFilters) {
      if (this.filtersOutUnwatch) this.filtersOutUnwatch();

      this.category = newFilters.category;

      this.$watch('filtersOut', newFiltersOut => {
        this.$emit('input', newFiltersOut);
      });
    },
    cleanUpSegment() {
      this.segmentSelection = null;
      this.cleanUpBrand();
    },
    cleanUpBrand() {
      this.brandSelection = null;
    },
  },
};
</script>
