var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',[_c('h1',{staticClass:"display-1 mb-5"},[_vm._v("Forecast Projects")]),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.loading ? [] : _vm.projects,"item-key":"id","multi-sort":"","items-per-page":10},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"end","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"to":"/forecast/wizard","color":"primary","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-shape-rectangle-plus")]),_vm._v("Create new project")],1)],1)],1)],1)]},proxy:true},{key:"item.name",fn:function(props){return [_c('router-link',{attrs:{"to":_vm.projectUrl(props.item)}},[_vm._v(_vm._s(props.item.name))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toHumanDateTime")(item.created_on))+" ")]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toHumanDateTime")(item.updated_on))+" ")]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_by.fullname ? item.created_by.fullname : item.created_by.id)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == 1 ? 'OPEN' : item.status == 2 ? 'FINALIZED' : 'DELETED')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status == 2)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.editProject(item)}}},on),[_vm._v(" mdi-eye-outline ")])]}}],null,true)},[_c('span',[_vm._v("View project")])]):_vm._e(),(item.status == 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.editProject(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit project")])]):_vm._e(),(item.status == 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.showConfirmDelete(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete project")])]):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px"},on:{"keydown":function($event){$event.stopPropagation();return _vm.dialogKeydown.apply(null, arguments)}},model:{value:(_vm.copyOpened),callback:function ($$v) {_vm.copyOpened=$$v},expression:"copyOpened"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Copy "+_vm._s(_vm.copyingItem ? _vm.copyingItem.name : ''))])]),_c('v-card-text',[_vm._v(" Do you want to create a copy of this project and its active scenarios? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":"","loading":_vm.copyLoading},on:{"click":function($event){return _vm.confirmCopyDialog(_vm.copyingItem)}}},[_vm._v("Copy")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.copyLoading},on:{"click":function($event){_vm.copyOpened = false}}},[_vm._v("Cancel")])],1)],1)],1),_c('confirmation-message',{attrs:{"show":_vm.confirmDelete,"icon":"warning","title":"Delete permanently","message":("Are you sure you want to delete the project '" + _vm.projectName + "'?.\n This action cannot not be undone."),"button1-text":"Cancel","button2-text":"Delete","button2-color":"error darken-1","button2-type":"solid"},on:{"button1":function($event){_vm.confirmDelete = false},"button2":_vm.removeProject}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }