import store from '@/store';

const module = 'filterCatalog';

export const filterCatalog = {
  getDataSources,
  getCatalogs,
  clearCatalogs,
  getFilters,
  state: store.state.filterCatalog,
};

function getDataSources() {
  return store.dispatch(`${module}/getDataSources`);
}

function getCatalogs(params) {
  return store.dispatch(`${module}/getCatalogs`, params);
}
function clearCatalogs() {
  return store.dispatch(`${module}/clearCatalogs`);
}

function getFilters(value) {
  return store.dispatch(`filterCatalog/getFilters`, value);
}
