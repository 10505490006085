<template>
  <div id="background">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
};
</script>

<style lang="scss">
#background {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fdfcfa;
}
</style>
