import { catalogService } from '@/services/';

const SET_PARAMETERS_CATALOGS = 'SET_PARAMETERS_CATALOGS';
const LOADING_PARAMETERS_CATALOGS = 'LOADING_PARAMETERS';

export const mutationTypes = {
  SET_PARAMETERS_CATALOGS,
};

const state = {
  parameters: { dump_date: null, categories: [] },
  loading: false,
};

const actions = {
  async getCatalogs({ commit }) {
    commit(LOADING_PARAMETERS_CATALOGS, true);
    catalogService
      .getAll()
      .then(result => commit(SET_PARAMETERS_CATALOGS, result))
      // .catch(() => commit(DATA_SOURCES_FAILED))
      .finally(() => commit(LOADING_PARAMETERS_CATALOGS, false));
  },
};

const mutations = {
  // [mutationTypes.SET_PARAMETERS_CATALOGS](state, parameters) {
  [SET_PARAMETERS_CATALOGS](state, parameters) {
    state.parameters = {
      ...parameters,
    };
  },

  [LOADING_PARAMETERS_CATALOGS](state, status) {
    state.loading = status;
  },
};

export const catalog = {
  namespaced: true,
  state,
  actions,
  mutations,
};
