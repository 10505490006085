import Vue from 'vue';

const FieldRulesMixin = Vue.mixin({
  data: function() {
    return {
      requiredRules: [v => !!v || 'This field is required'],
      projectNameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 200) || 'Name must be less than 200 characters',
      ],
      requiredNumberRules: [
        v =>
          (v != null && /^[+-]?\d*([,.]\d{3})*([,.]\d+)?$/gm.test(v)) ||
          'A number is required',
      ],
      positiveNumberRules: [
        v =>
          v == null ||
          v === '' ||
          /^\d*([,.]?\d{3})*([,.]\d{2})?$/gm.test(v) ||
          'Enter a positive number or leave the field empty',
      ],
      requiredPositiveNumberRules: [
        v =>
          (v != null &&
            /^\d*([,.]?\d{3})*([,.]\d{2})?$/gm.test(v) &&
            parseInt(v) > -1) ||
          'Enter a positive number',
      ],
    };
  },
});

export default FieldRulesMixin;
