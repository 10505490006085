// DO NOT configure values here use the public/api.json to specify a middleware, main.js wil populate these values.
export const config = {
  //  apiUrl: 'https://func-gum-app-prd.azurewebsites.net/api/',
  apiUrl: 'https://func-gum-app-dev.azurewebsites.net/api/',
  // apiUrl: 'http://localhost:7071/api/',
  oauth: {
    // clientId: 'd86f71b1-a3b8-4cb2-beb6-554007fea3e5',
    clientId: '3538631e-1b86-4dc3-a7e9-3ab9a48b3b5e',
    tenantId: 'b3bcee1f-cc6e-46b6-82d9-bdcf810bd0c1',
  },
};
