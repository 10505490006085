<template>
  <v-dialog v-model="showDialog" persistent max-width="350">
    <v-card>
      <v-card-title class="headline">
        <v-icon v-if="icon === 'info'" large left color="info">
          mdi-information
        </v-icon>
        <v-icon v-if="icon === 'warning'" large left color="warning">
          mdi-alert
        </v-icon>
        <v-icon v-if="icon === 'error'" large left color="error">
          mdi-alert-octagon
        </v-icon>
        <span class="headline  font-weight-light">{{ title }}</span>
      </v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="button2Text"
          :loading="executing === 'button2'"
          :disabled="executing && executing !== 'button2'"
          :color="button2Color"
          class="mr-2"
          :outlined="button2Type !== 'solid'"
          :text="button2Type !== 'solid'"
          @click="emitClick('button2')"
          >{{ button2Text }}
        </v-btn>
        <v-btn
          :loading="executing === 'button1'"
          :disabled="executing && executing !== 'button1'"
          :color="button1Color"
          :outlined="button1Type !== 'solid'"
          :text="button1Type !== 'solid'"
          @click="emitClick('button1')"
          >{{ button1Text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { reactive, toRefs, watch } from '@vue/composition-api';

export default {
  name: 'SettingsSummary',
  props: {
    show: Boolean,
    title: String,
    icon: {
      type: String,
      validator: val => ['info', 'warning', 'error'].includes(val),
      default: 'info',
    },
    message: String,
    button1Color: { type: String, default: 'primary darken-1' },
    button1Text: { type: String, default: 'Ok' },
    button1Type: {
      type: String,
      validator: val => ['text', 'solid'].includes(val),
      default: 'text',
    },
    button2Color: { type: String, default: 'primary darken-1' },
    button2Text: { type: String },
    button2Type: {
      type: String,
      validator: val => ['text', 'solid'].includes(val),
      default: 'text',
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      //   title: props.title,
      showDialog: false,
      executing: null,
    });

    watch(
      () => props.show,
      value => {
        state.showDialog = value;
        state.executing = null;
      }
    );

    const emitClick = button => {
      state.executing = button;
      emit(button);
    };

    return {
      ...toRefs(state),
      emitClick,
    };
  },
};
</script>
