<template>
  <v-card outlined>
    <v-container fluid>
      <v-row no-gutters>
        <v-col>
          <span class="title pl-4">Reseach Inputs</span>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row class="mt-2" no-gutters>
        <v-col cols="6">
          <v-container class="ml-5 pb-0">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model.number="form.cps"
                  label="CPS"
                  :rules="requiredNumberRules"
                  class="text-right"
                  style="width:180px"
                  persistent-placeholder
                  :disabled="disabled"
                  placeholder="0"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <!-- TRIAL -->
        <v-col cols="12">
          <v-container class="ml-5 pt-0 pb-0">
            <v-row>
              <v-col>
                <span class="subtitle-1">Trial</span>
                <v-divider class="mr-5"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.trial_tried_product"
                  label="HH Tried Product (YR1)"
                  :rules="requiredNumberRules"
                  class="text-right"
                  suffix="%"
                  persistent-placeholder
                  :disabled="disabled"
                  placeholder="0.0"
                  style="width:230px"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.trial_packs_purchased"
                  label="Packs_purchased at Trial"
                  :rules="requiredNumberRules"
                  class="text-right"
                  suffix="%"
                  :disabled="disabled"
                  persistent-placeholder
                  placeholder="0.0"
                  style="width:230px"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <!-- REPEAT -->
        <v-col class="ml-5 pt-0">
          <v-container class="pt-0">
            <v-row>
              <v-col>
                <span class="subtitle-1">Repeat</span>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.repeat_at_least_once"
                  label="HH Repeat purchases at least once"
                  :rules="requiredNumberRules"
                  class="text-right"
                  suffix="%"
                  :disabled="disabled"
                  persistent-placeholder
                  placeholder="0.0"
                  style="width:230px"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.repeat_purchases_per_hh"
                  label="Repeat purchases per HH"
                  :rules="requiredNumberRules"
                  class="text-right"
                  style="width:230px"
                  :disabled="disabled"
                  persistent-placeholder
                  placeholder="0.0"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.repeat_pack_buy_each"
                  label="Packs buy each repeat purchase"
                  :rules="requiredNumberRules"
                  class="text-right"
                  :disabled="disabled"
                  persistent-placeholder
                  placeholder="0.0"
                  style="width:230px"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <span class="title pl-4">Cannibalisation</span>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-container class="ml-5 pb-0">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.sova"
                  label="SOVA"
                  :rules="requiredNumberRules"
                  class="text-right"
                  style="width:230px"
                  suffix="%"
                  :disabled="disabled"
                  persistent-placeholder
                  placeholder="0.0"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.fga_volume"
                  label="FGA Volume"
                  :rules="requiredNumberRules"
                  class="text-right"
                  suffix="%"
                  :disabled="disabled"
                  persistent-placeholder
                  placeholder="0.0"
                  style="width:230px"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.fga_value"
                  label="FGA Value"
                  :rules="requiredNumberRules"
                  class="text-right"
                  suffix="%"
                  :disabled="disabled"
                  persistent-placeholder
                  placeholder="0.0"
                  style="width:230px"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-container class="pb-0 pt-0">
            <v-row>
              <v-col>
                <span class="title pl-4">Bases Volume</span>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="ml-5">
                <v-text-field
                  v-model.number="form.bases_volume_forecast"
                  label="Forecast"
                  :rules="requiredNumberRules"
                  class="text-right"
                  style="width:230px"
                  :disabled="disabled"
                  persistent-placeholder
                  placeholder="0"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api';

export default {
  name: 'BasesInput',
  props: {
    list: Array,
    value: Object,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    //{ emit }

    const updateFormProps = () => {
      const f = form.value;
      const v = props.value;
      f.cps = v.cps;
      f.trial_tried_product = v.trial_tried_product;
      f.trial_packs_purchased = v.trial_packs_purchased;
      f.repeat_pack_buy_each = v.repeat_pack_buy_each;
      f.repeat_purchases_per_hh = v.repeat_purchases_per_hh;
      f.repeat_at_least_once = v.repeat_at_least_once;
      f.sova = v.sova ? v.sova * 100 : null;
      f.fga_volume = v.fga_volume;
      f.fga_value = v.fga_value;
      f.bases_volume_forecast = v.bases_volume_forecast;
    };

    const form = ref({
      cps: null,
      trial_tried_product: null,
      trial_packs_purchased: null,
      repeat_pack_buy_each: null,
      repeat_purchases_per_hh: null,
      repeat_at_least_once: null,
      sova: null,
      fga_volume: null,
      fga_value: null,
      bases_volume_forecast: null,
    });

    updateFormProps();
    watch(() => props.value, updateFormProps);

    watch(
      form,
      () =>
        emit('input', {
          ...form.value,
          sova: form.value.sova ? form.value.sova / 100 : null,
        }),
      { deep: true }
    );

    return {
      form,
    };
  },
};
</script>

<style></style>
