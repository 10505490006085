import { config } from '@/config';
import { authService } from '@/services';
import axios from 'axios';

export const adGraphService = {
  getUser,
};

async function getUser(userId) {
  const accessToken = await authService.getAccessToken();
  const endpoint = `https://graph.windows.net/${config.oauth.tenantId}/users/${userId}?api-version=1.6`;
  return axios
    .create({
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .get(endpoint)
    .then(res => res.data);
}
