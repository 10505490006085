export const createProjectStructure = () => ({
  // dump_date: 20220502,
  // category: 'MULTI PURPOSE CLEANERS',
  // segment: 'BATHROOM',
  // brand: 'CILLIT BANG',
  // test_country: 'FRANCE',
  // forecast_basis: 'Category',
  // bases_concept_id: null,
  // name: '',
  // cps: null,
  // trial_tried_product: null,
  // trial_packs_purchased: null,
  // fga_volume: null,
  // fga_value: null,
  // plan_marketing_spend: null,
  // plan_distribution: null,
  // id: null,
  // repeat_pack_buy_each: null,
  // repeat_purchases_per_hh: null,
  // repeat_at_least_once: null,
  dump_date: null,
  category: null,
  segment: null,
  brand: null,
  test_country: null,
  forecast_basis: null,
  bases_concept_id: null,
  name: null,
  cps: null,
  trial_tried_product: null,
  trial_packs_purchased: null,
  fga_volume: null,
  fga_value: null,
  plan_marketing_spend: null,
  plan_distribution: null,
  id: null,
  repeat_pack_buy_each: null,
  repeat_purchases_per_hh: null,
  repeat_at_least_once: null,
  bases_volume_forecast: null,
  sova: null,
  // bases_volume_forecast: 7000000,
  // sova: 0.07,
  updated_on: null,
  created_on: null,
  deleted: false,
  deleted_on: null,
  created_by: {
    id: null,
    fullname: null,
  },
  selected_countries: [],
  // selected_countries: [
  //   {
  //     country: 'FRANCE',
  //     price_override: 0.0,
  //     nr_percentage: 0.6,
  //     nr_override: 0.0,
  //   },
  //   {
  //     country: 'GERMANY',
  //     price_override: 0.0,
  //     nr_percentage: 0.5,
  //     nr_override: 0.0,
  //   },
  //   {
  //     country: 'GREAT BRITAIN',
  //     price_override: 0.0,
  //     nr_percentage: 0.4,
  //     nr_override: 0.0,
  //   },
  // ],
  sku_rows: [],
  // sku_rows: [
  //   {
  //     launch_name: 'SKU 1',
  //     sku_name: 'Starter Kit',
  //     volume_split_ratio: 0.45,
  //     launch_price: 5.99,
  //   },
  //   {
  //     launch_name: 'SKU 2',
  //     sku_name: 'Refill',
  //     volume_split_ratio: 0.25,
  //     launch_price: 4.99,
  //   },
  //   {
  //     launch_name: 'SKU 3',
  //     sku_name: 'SKU 3',
  //     volume_split_ratio: 0.3,
  //     launch_price: 3.99,
  //   },
  // ],
  status: 1, //OPEN: int = 1  FINALIZED: int = 2  ARCHIVED: int = 3
  step: 0,
});
